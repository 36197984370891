import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'raf-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons';
import * as serviceWorker from './serviceWorker';
import {createBrowserHistory} from "history";
import {Router, Route, Switch} from "react-router-dom";
import {Helmet} from "react-helmet";
import Home from './layouts/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import ReactGA from 'react-ga';
import SmartBanner from 'react-smartbanner';
import '../node_modules/react-smartbanner/dist/main.css';
import Amplify from 'aws-amplify';

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "ocvapps",
                endpoint: "https://oc1rhn99vi.execute-api.us-east-1.amazonaws.com/beta",
                region: "us-east-1"
            }
        ]
    }
});
const hist = createBrowserHistory();
ReactGA.initialize("UA-134449959-2");
hist.listen(location1 => {
    ReactGA.pageview(location1.pathname);
    window.scrollTo(0, 0);
});

library.add(fab);
library.add(fas);

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#222'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        useNextVariants: true
    }
});
ReactDOM.render(
        <>
            <SmartBanner daysHidden={0} daysReminder={0} title={"Greene County Sheriff's Office"} />
            <Router history={hist}>
                <MuiThemeProvider theme={theme}>
                    <Switch>
                        <Route key={"homeIndex"} path={"/"} component={Home} />
                    </Switch>
                </MuiThemeProvider>
            </Router>
            <Helmet>
                <title>Greene County Sheriff's Office</title>
                <meta name={"description"} content={"Proudly serving the citizens of Greene County in the best manner possible!"} />
            </Helmet>
        </>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();