import React from 'react';
import {withFormsy} from 'formsy-react';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import './OCVFormComponents.css';

export default withFormsy(function OCVRadio(props: any) {
    const changeValue = (event: {target: any}) => {
        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        // Important: Don't skip this step. This pattern is required
        // for Formsy to work.
        props.setValue(event.target.value);
    };

    const {label, getValue, elements, getErrorMessage} = props;
    return (
        <div className='OCVFormComponentsDiv'>
            <FormControl fullWidth={true}>
                <FormLabel>{label}</FormLabel>
                <RadioGroup
                    aria-label="Gender"
                    name="gender1"
                    value={getValue()}
                    onChange={changeValue}
                    row>
                    {elements.map((item: any, index: string | number) =>(
                        <FormControlLabel key={item + "-" + index} value={item} control={<Radio color="primary" />} label={item} />
                    ))}
                </RadioGroup>
                <span className='OCVFormComponentsSpan'>{getErrorMessage()}</span>
            </FormControl>
        </div>
    );
});