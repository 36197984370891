import React, {PureComponent} from "react";
import {Col, Row} from "reactstrap";
import OCVMapView from "./OCVMapComponents/OCVMapView/OCVMapView";
import OCVMapList from "./OCVMapComponents/OCVMapList/OCVMapList";
import OCVMapDetailView from "./OCVMapComponents/OCVMapDetailView/OCVMapDetailView";
import OCVMapToolbar from "./OCVMapComponents/OCVMapToolbar/OCVMapToolbar";
import pinBlack from "./pin/pinblack.png";
import pinBlue from "./pin/pinblue.png";
import pinBrown from "./pin/pinbrown.png";
import pinGray from "./pin/pingray.png";
import pinGreen from "./pin/pingreen.png";
import pinOrange from "./pin/pinorange.png";
import pinPink from "./pin/pinpink.png";
import pinPurple from "./pin/pinpurple.png";
import pinRed from "./pin/pinred.png";
import pinYellow from "./pin/pinyellow.png";
import './OCVMap.css';

//Note: THIS IS VERY JANKETY
export default class OCVMap extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            locations: [],
            filteredLocations: [],
            singleEntry: false,
            selectedEntry: {},
            legendOpen: false,
            filterOpen: false,
            isFiltering: false,
            legend: [],
            pinSort: [],
            pinSortSecondary: [],
            pinTypeFilter: [],
            pinSortFilter: [],
            pinSortSecondaryFilter: [],
            search: ""
        };
        this.filterPinType = this.filterPinType.bind(this);
        this.filterPinSort = this.filterPinSort.bind(this);
        this.filterPinSortSecondary = this.filterPinSortSecondary.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        const {link} = this.props;
        try {
            fetch(link, {mode: "cors"})
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        locations: data,
                        filteredLocations: data,
                    });
                });
        } catch (e) {};
    };
    componentDidUpdate(prevProps: any, prevState: any) {
        // Typical usage (don't forget to compare props):
        if (this.state.locations !== prevState.locations) {
            let locations = this.state.locations.slice().filter((location : any) => {return location["sort"].value !== ""});
            let locationsSec = this.state.locations.slice().filter((location : any) => {return location["sortSecondary"].value !== ""});
            let legend = this.state.locations.slice().map((location: any) => {
                return {
                    title: location.type.title,
                    color: location.type.pin,
                    pin: this.getIcon(location.type.pin)
                };
            });
            let pinSort = locations.slice().map((location: any) => {
                return {
                    title: location["sort"].name,
                    value: location["sort"].value
                };
            });
            let pinSortSecondary = locationsSec.slice().map((location: any) => {
                return {
                    title: location["sortSecondary"].name,
                    value: location["sortSecondary"].value
                };
            });
            this.setState({
                legend: legend.filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                    return arr.map(mapObj => mapObj["title"]).indexOf(obj["title"]) === pos;
                }),
                pinSort: pinSort.filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                    return arr.map(mapObj => mapObj["value"]).indexOf(obj["value"]) === pos;
                }),
                pinSortSecondary: pinSortSecondary.filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                    return arr.map(mapObj => mapObj["value"]).indexOf(obj["value"]) === pos;
                })
            });
        };
    };

    showDetail = (location: any) => {
        this.setState({
            singleEntry: true,
            selectedEntry: location
        });
    };
    exitSingleEntry = () => {
        this.setState({
            singleEntry: false,
            selectedEntry: {}
        });
    };
    toggleLegend = () => {
        this.setState({
            legendOpen: !this.state.legendOpen
        });
    };
    toggleFilter = () => {
        this.setState({
            filterOpen: !this.state.filterOpen
        });
    };
    clearFilters = () => {
        this.setState({
            filteredLocations: this.state.locations,
            pinTypeFilter: [],
            pinSortFilter: [],
            pinSortSecondaryFilter: [],
            isFiltering: false
        });
        let locations = this.state.locations.slice().filter((location: any) => {return location["sort"].value !== ""});
        let locationsSec = this.state.locations.slice().filter((location: any) => {return location["sortSecondary"].value !== ""});
        let legend = this.state.locations.slice().map((location: any) => {
            return {
                title: location.type.title,
                color: location.type.pin,
                pin: this.getIcon(location.type.pin)
            };
        });
        let pinSort = locations.slice().map((location: any) => {
            return {
                title: location["sort"].name,
                value: location["sort"].value
            };
        });
        let pinSortSecondary = locationsSec.slice().map((location: any) => {
            return {
                title: location["sortSecondary"].name,
                value: location["sortSecondary"].value
            };
        });
        this.setState({
            legend: legend.filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                return arr.map(mapObj => mapObj["title"]).indexOf(obj["title"]) === pos;
            }),
            pinSort: pinSort.filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                return arr.map(mapObj => mapObj["value"]).indexOf(obj["value"]) === pos;
            }),
            pinSortSecondary: pinSortSecondary.filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                return arr.map(mapObj => mapObj["value"]).indexOf(obj["value"]) === pos;
            })
        });
    };
    filterPinType (event: {target: {value: any}}) {
        let filteredItems: any[] = [];
        if (event.target.value.length !== 0) {
            for (let index in event.target.value) {
                Array.prototype.push.apply(filteredItems, this.returnMarkers(event.target.value[index], 'pinType', this.state.locations.slice()));
            }
            if (this.state.pinSortFilter.length !== 0) {
                let temp: any[] = [];
                for (let index in this.state.pinSortFilter) {
                    Array.prototype.push.apply(temp, this.returnMarkers(this.state.pinSortFilter[index], 'sort', filteredItems));
                }
                filteredItems = temp;
                if(this.state.pinSortSecondaryFilter.length !== 0) {
                    let tempTwo: any[] = [];
                    for (let index in this.state.pinSortSecondaryFilter) {
                        Array.prototype.push.apply(tempTwo, this.returnMarkers(this.state.pinTypeFilter[index], 'pinType', filteredItems));
                    }
                    filteredItems = tempTwo;
                }
            }
        }
        else {
            if(this.state.pinSortSecondaryFilter.length !== 0) {
                let tempTwo: any[] = [];
                for (let index in this.state.pinSortSecondaryFilter) {
                    Array.prototype.push.apply(tempTwo, this.returnMarkers(this.state.pinTypeFilter[index], 'pinType', this.state.locations.slice()));
                }
                filteredItems = tempTwo;
            } else {
                if (this.state.pinSortFilter !== 0) {
                    for (let index in this.state.pinSortFilter) {
                        Array.prototype.push.apply(filteredItems, this.returnMarkers(this.state.pinSortFilter[index], 'sort', this.state.locations.slice()));
                    }
                }
            }
        }

        filteredItems = filteredItems.filter((location: any) => {return location["sort"].value !== ""});

        this.setState({
            pinTypeFilter: event.target.value,
            pinSort: filteredItems.slice().map((location: any) =>{
                return {title: location["sort"].name, value: location["sort"].value,}
            }).filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                return arr.map(mapObj => mapObj["value"]).indexOf(obj["value"]) === pos;
            }),
            pinSortSecondary: filteredItems.slice().map((location: any) =>{
                return {title: location["sortSecondary"].name, value: location["sortSecondary"].value,}
            }).filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                return arr.map(mapObj => mapObj["value"]).indexOf(obj["value"]) === pos;
            }),
            filteredLocations: filteredItems.filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                return arr.map(mapObj => mapObj["title"]).indexOf(obj["title"]) === pos;
            }),
            isFiltering: true,
            showOverlayView: filteredItems.length === 0
        });
    };
    filterPinSort (event: {target: {value: any}}) {
        this.setState({
            sortSecondaryFilter: []
        });
        let filteredItems: any[] = [];
        if (event.target.value.length !== 0) {
            for (let index in event.target.value) {
                Array.prototype.push.apply(filteredItems, this.returnMarkers(event.target.value[index], 'sort', this.state.locations.slice()));
            }
            if(this.state.pinTypeFilter.length !== 0) {
                let temp: any[] = [];
                for (let index in this.state.pinTypeFilter) {
                    Array.prototype.push.apply(temp, this.returnMarkers(this.state.pinTypeFilter[index], 'pinType', filteredItems));
                }
                filteredItems = temp;
            }
        } else {
            for (let index in this.state.pinTypeFilter) {
                Array.prototype.push.apply(filteredItems, this.returnMarkers(this.state.pinTypeFilter[index], 'pinType', this.state.locations.slice()));
            }
        }

        filteredItems = filteredItems.filter((location: any) => {return location["sortSecondary"].value !== ""});

        this.setState({
            pinSortFilter: event.target.value,
            pinSortSecondary: filteredItems.slice().map((location: any) =>{
                return {title: location["sortSecondary"].name, value: location["sortSecondary"].value,}
            }).filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                return arr.map(mapObj => mapObj["value"]).indexOf(obj["value"]) === pos;
            }),
            filteredLocations: filteredItems.filter((obj: {[x: string]: any}, pos: any, arr: {map: (arg0: (mapObj: any) => any) => {indexOf: (arg0: any) => void}}) => {
                return arr.map(mapObj => mapObj["title"]).indexOf(obj["title"]) === pos;
            }),
            isFiltering: true,
            showOverlayView: filteredItems.length === 0
        });
    };
    filterPinSortSecondary (event: {target: {value: any}}) {
        let filteredItems: any[] | never[] = [];
        if (event.target.value.length !== 0) {
            for (let index in event.target.value) {
                Array.prototype.push.apply(filteredItems, this.returnMarkers(event.target.value[index], 'sortSecondary', this.state.locations.slice()));
            }
            if(this.state.pinTypeFilter.length !== 0) {
                let temp: any[] = [];
                for (let index in this.state.pinTypeFilter) {
                    Array.prototype.push.apply(temp, this.returnMarkers(this.state.pinTypeFilter[index], 'pinType', filteredItems));
                }
                filteredItems = temp;
            }
        }
        else{
            for (let index in this.state.pinSortFilter) {
                Array.prototype.push.apply(filteredItems, this.returnMarkers(this.state.pinSortFilter[index], 'sort', this.state.locations.slice()));
            }
            if(this.state.pinTypeFilter.length !== 0) {
                let temp: any[] = [];
                for (let index in this.state.pinTypeFilter) {
                    Array.prototype.push.apply(temp, this.returnMarkers(this.state.pinTypeFilter[index], 'pinType', filteredItems));
                }
                filteredItems = temp;
            }
        }
        filteredItems = filteredItems.filter((location : any) => {return location["sortSecondary"].value !== ""});
        this.setState({
            pinSortSecondaryFilter: event.target.value,
            filteredLocations: filteredItems,
            isFiltering: true,
            showOverlayView: filteredItems.length === 0
        });
    };
    handleSearch (event: {target: {value: any}}) {
        let newItems = this.state.locations;
        if (event.target.value !== "") {
            newItems = this.state.locations.filter((location: any) => {
                let lowerLocation =  event.target.value.toLowerCase();
                let title = location.title.toLowerCase();
                let address = location.address.toLowerCase();
                return(title.includes(lowerLocation) || address.includes(lowerLocation));
            });
        }
        this.setState({search: event.target.value, filteredLocations: newItems});
    };
    returnMarkers = (filter: string, filterType: string, markers: any[]) => {
        switch (filterType) {
            case 'pinType':
                return markers.filter(marker => (marker.type.pin === filter && marker.type.pin !== ""));
            case 'sort':
                return markers.filter(marker => (marker['sort'].value === filter) &&  marker['sort'].value !== "");
            case 'sortSecondary':
                return markers.filter(marker => (marker['sortSecondary'].value === filter && marker['sortSecondary'].value !== ""));
            default:
                return markers;
        };
    };
    getIcon = (color: string) => {
        switch (color) {
            case "black":
                return pinBlack;
            case "blue":
                return pinBlue;
            case "brown":
                return pinBrown;
            case "gray":
                return pinGray;
            case "green":
                return pinGreen;
            case "orange":
                return pinOrange;
            case "pink":
                return pinPink;
            case "purple":
                return pinPurple;
            case "red":
                return pinRed;
            case "yellow":
                return pinYellow;
            default:
                return pinRed;
        };
    };

    render() {
        const {classes, subtypes} = this.props;
        return (
            <>
                <Row>
                    {(this.state.singleEntry )
                        ? <Col className='OCVMapCol' xs={{size: "10", order: "2"}} sm={{size: "10", order: "2"}} md={5}>
                            <OCVMapDetailView leaveDetail={()=> this.exitSingleEntry()} selectedLocation={this.state.selectedEntry} />
                        </Col>
                        : <Col className='OCVMapCol' xs={{size: "10", order: "2"}} sm={{size: "10", order: "2"}} md={5}>
                            <OCVMapToolbar  search={this.state.search} handleSearch={this.handleSearch} clearFilters={() => this.clearFilters()} legend={this.state.legend} showLegend={this.state.legendOpen} showFilter={this.state.filterOpen} toggleLegend={() => this.toggleLegend()} toggleFilter={() => this.toggleFilter()}
                                pinTypeFilter={this.state.pinTypeFilter} pinTypeFilterChange={this.filterPinType}
                                pinSort={this.state.pinSort} pinSortFilter={this.filterPinSort} pinSorted={this.state.pinSortFilter}
                                pinSortSecondary={this.state.pinSortSecondary} pinSortSecondaryFilter={this.filterPinSortSecondary} pinSortedSecondary={this.state.pinSortSecondaryFilter} />
                            <OCVMapList onSelectedEntry={this.showDetail} classes={classes} locations={this.state.filteredLocations} />
                        </Col>}
                    <Col className='OCVMapCol' xs={{size: "10", order: "1"}} sm={{size: "10", order: "2"}} md={7}>
                        <OCVMapView showOverlayView={this.state.showOverlayView} isFiltering={this.state.isFiltering} subtypes={subtypes} onMarkerClick={this.showDetail} classes={classes} locations={this.state.filteredLocations} googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyAEOZZNm6jFVe3j3ISl_Ha9BeODMYrdqaY"}
                            loadingElement={<div className='OCVMapDivOne' />}
                            containerElement={<div className='OCVMapDivTwo' />}
                            mapElement={<div className='OCVMapDivOne' />} />
                    </Col>
                </Row>
            </>
        );
    };
};