import React from "react";
import {withFormsy} from 'formsy-react';
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import './OCVFormComponents.css';

export default withFormsy(function OCVFormSlider(props: any) {
    const changeValue = (event: {currentTarget: {value: any}}) => {
        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        // Important: Don't skip this step. This pattern is required
        // for Formsy to work.
        props.setValue(event.currentTarget.value);
    };

    const {label, min, max, step, getValue, getErrorMessage} = props;
    return (
        <div className='OCVFormComponentsDiv'>
            <FormLabel>{label}</FormLabel>
            <input onChange={changeValue} type="range" min={min} max={max} step={step} value={getValue() || min} className={"formSlider"} />
            <span>{getValue() || min}</span>
            <span className='OCVFormComponentsSpan'>{getErrorMessage()}</span>
        </div>
    );
});