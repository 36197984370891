import 'core-js/stable';
import 'core-js/es/map';
import 'regenerator-runtime/runtime';
import React from "react";
import OCVManifestModel from "./OCVManifestModel";
import TopBar from "../components/MainComponents/TopBar/TopBar";
import NavBar from "../components/MainComponents/NavBar/NavBar";
import Slider from "../components/MainComponents/Slider/Slider";
import FeatureBar from "../components/MainComponents/FeatureBar/FeatureBar";
import {Route, Switch} from "react-router-dom";
import OCVPage from "../components/OCVFeatures/OCVPage/OCVPage";
import {Helmet} from "react-helmet";
import OCVBlog from "../components/OCVFeatures/OCVBlog/OCVBlog";
import OCVForm from "../components/OCVFeatures/OCVForm/OCVForm";
import OCVContacts from "../components/OCVFeatures/OCVContacts/OCVContacts/OCVContacts";
import Footer from "../components/MainComponents/Footer/Footer";
import ViewOurApp from "../components/MainComponents/ViewOurApp/ViewOurApp";
import Breadcrumb from "../components/MainComponents/Breadcrumb/Breadcrumb";
import GoogleSearch from "../components/MainComponents/GoogleSearch/GoogleSearch";
import OCVCalendar from "../components/OCVFeatures/OCVCalendar/OCVCalendar";
import OCVMap from "../components/OCVFeatures/OCVMap/OCVMap";
import Label from "../components/MainComponents/Label";
import ImageBar from "../components/MainComponents/ImageBar";
import TwoColumnView from "../components/MainComponents/TwoColumnView/TwoColumnView";
import HorizontalSublayout from "../components/MainComponents/HorizontalSublayout/HorizontalSublayout";
import Iframe from 'react-iframe';
import OCVBlogSlider from "../components/OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider";
import OCVSubmenuPage from "../components/OCVFeatures/OCVSubmenuPage/OCVSubmenuPage";
import OCVAlerts from "../components/OCVFeatures/OCVAlerts/OCVAlerts";

interface OCVRoute {
    path: string;
    exact: boolean;
    page: JSX.Element[];
};

export default class OCVManifest {
    manifestData!: OCVManifestModel;

    static buildMainPage(manifestData: any, styles: any) {
        let views: any = manifestData['views'];
        let mainPage: JSX.Element[] = [];
        for (let i=0; i < manifestData['homeOrder'].length; i++) {
            let view = views[manifestData['homeOrder'][i]];
            switch (view['type']) {
                case 'topbar':
                    mainPage.push(<TopBar key={manifestData['homeOrder'][i]} classes={styles} manifestData={manifestData} topbarConfig={view['config']}/>);
                    break;
                case 'label':
                    mainPage.push(<Label key={manifestData['homeOrder'][i]} labelConfig={view['config']} />);
                    break;
                case 'webNavbar':
                    mainPage.push(<NavBar key={manifestData['homeOrder'][i]} classes={styles} manifestData={manifestData} navbarConfig={view['config']}/>);
                    break;
                case 'slider':
                    mainPage.push(<Slider key={manifestData['homeOrder'][i]} sliderConfig={view['config']}/>);
                    break;
                case 'imageBar':
                    mainPage.push(<ImageBar key={manifestData['baseLayout'][i]} classes={styles} imageBarConfig={view['config']} manifestData={manifestData}/>);
                    break;
                case 'featureBar':
                    mainPage.push(<FeatureBar key={manifestData['homeOrder'][i]} manifestData={manifestData} features={manifestData['features']} classes={styles} featureBarConfig={view['config']}/>);
                    break;
                case 'footer':
                    mainPage.push(<Footer key={manifestData['homeOrder'][i]} classes={styles} manifestData={manifestData} footerConfig={view['config']}/>);
                    break;
                case 'twoColumnView':
                    mainPage.push(<TwoColumnView key={manifestData['baseLayout'][i]} classes={styles} manifestData={manifestData} layoutConfig={view['config']} />);
                    break;
                case 'horizontalLayout':
                    mainPage.push(<HorizontalSublayout key={manifestData['baseLayout'][i]} classes={styles} manifestData={manifestData} config={view['config']}/>);
                    break;
                default:
                    break;
            };
        }
        return mainPage;
    };

    static buildWebPage(manifestData: any, classes: any, component: JSX.Element, title: string) {
        let views = manifestData['views'];
        let webPage: JSX.Element[] = [];
        webPage.push(<Helmet key={title}>
            <title>{title} | Greene County Sheriff's Office</title>
            </Helmet>);
        for(let i=0; i < manifestData['baseLayout'].length; i++) {
            if (manifestData['baseLayout'][i] === "{component}"){
                webPage.push(<Breadcrumb key={title + i} classes={classes} title={title}/>,component);
            } else {
                let view = views[manifestData['baseLayout'][i]];
                switch (view['type']) {
                    case 'topbar':
                        webPage.push(<TopBar key={title + manifestData['baseLayout'][i]} manifestData={manifestData} classes={classes} topbarConfig={view['config']}/>);
                        break;
                    case 'webNavbar':
                        webPage.push(<NavBar key={title + manifestData['baseLayout'][i]} classes={classes} manifestData={manifestData} navbarConfig={view['config']}/>);
                        break;
                    case 'label':
                        webPage.push(<Label key={title + manifestData['homeOrder'][i]} labelConfig={view['config']} />);
                        break;
                    case 'slider':
                        webPage.push(<Slider key={title + manifestData['baseLayout'][i]} sliderConfig={view['config']}/>);
                        break;
                    case 'imageBar':
                        webPage.push(<ImageBar key={title + manifestData['baseLayout'][i]} classes={classes} imageBarConfig={view['config']} manifestData={manifestData}/>);
                        break;
                    case 'featureBar':
                        webPage.push(<FeatureBar key={title + manifestData['baseLayout'][i]} classes={classes} manifestData={manifestData} featureBarConfig={view['config']}/>);
                        break;
                    case 'footer':
                        webPage.push(<Footer key={title + manifestData['baseLayout'][i]} classes={classes} manifestData={manifestData} footerConfig={view['config']}/>);
                        break;
                    case 'twoColumnView':
                        webPage.push(<TwoColumnView key={title + manifestData['baseLayout'][i]} classes={classes} manifestData={manifestData} layoutConfig={view['config']} />);
                        break;
                    case 'horizontalLayout':
                        webPage.push(<HorizontalSublayout key={title + manifestData['baseLayout'][i]} classes={classes} manifestData={manifestData} config={view['config']}/>);
                        break;
                    default:
                        webPage.push(<Breadcrumb key={title} title={title}/>,component);
                        break;
                };
            }
        }
        return webPage;
    };

    static buildPagesFromFeatures(manifestData: any, classes: any) {
        let features = manifestData['features'];
        const featureKeys = Object.keys(features);
        let response: OCVRoute[] = [];
        for (const key of featureKeys) {
            let feature = features[key];
            switch (feature['type']) {
                case 'page':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes, <OCVPage key={key + "page"} subtypes={feature['subtypes'] ? feature['subtypes'] : []} classes={classes} link={feature['url']}/>, feature.title)
                    });
                    break;
                case 'blog':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes, <OCVBlog submitATipLink={(feature.hasOwnProperty("other") && feature["other"].hasOwnProperty("submitATipLink")) && feature["other"]["submitATipLink"] === true} key={key + "blog"} subtypes={feature['subtype']} classes={classes} route={key}
                                                                                  link={feature['url']} title={feature.title}/>, feature.title)
                    });
                    break;
                case 'form':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes, <OCVForm classes={classes} formID={feature['formID']} link={feature['url']} key={key + "form"} submissionText={((feature["submissionText"] ) ? feature["submissionText"] : "This form has been submitted successfully. Please refresh the page to submit this form again.")}/>, feature.title)
                    });
                    break;
                case 'contacts':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes, <OCVContacts title={feature['title']} link={feature['url']} classes={classes} subtypes={feature['subtypes'] ? feature['subtypes'] : []} key={key + "contacts"}/>, feature.title)
                    });
                    break;
                case 'calendar':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData,classes,<OCVCalendar key={key+'-calendar'} link={feature['url']}/>, feature.title)
                    });
                    break;
                case 'map':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData,classes,<OCVMap subtypes={feature["subtype"]} classes={classes} key={key+'-calendar'} link={feature['url']}/>, feature.title)
                    });
                    break;
                case 'appMarketing':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes, <ViewOurApp key={"viewOurApp"} title={"View Our App"} manifest={manifestData} config={feature.other} classes={classes}/>, feature.title)
                    });
                    break;
                case 'blogSlider':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes,<OCVBlogSlider key={key + 'blogSlider'} link={feature['url']}/>, feature.title)
                    });
                    break;
                case 'webview':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes, <Iframe position={"relative"} key={key + "webview"} url={(feature['url']) ? feature['url'] : "http://www.google.com"} width={"100%"} height={"700px"} />, feature.title)
                    });
                    break;
                case 'submenu':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes, <OCVSubmenuPage key={key + "submenu"} items={feature['items']}  manifestData={manifestData} classes={classes}/>, feature.title)
                    });
                    break;
                case 'alerts':
                    response.push({
                        "path": key,
                        "exact": true,
                        "page": this.buildWebPage(manifestData, classes, <OCVAlerts key={key + "alerts"} link={feature['url']} route={key}  manifestData={manifestData} classes={classes}/>, feature.title)
                    });
                    break;
            };
        }
        return response;
    };

    static buildRoutes(manifestData: any, classes: any) {
        let routes = this.buildPagesFromFeatures(manifestData, classes);
        return (
            <Switch>
                {routes.map((route: OCVRoute, index:number) => (
                    <Route key={index + route.path + index} path={'/'+ route.path} render={() => route.page}/>
                ))}
                <Route key={"search"} path={'/search'} exact={true} render={() => this.buildWebPage(manifestData, classes, <GoogleSearch/>, 'Search' )}/>
                <Route key={"home-abrtybu"} to={'/'} render={() => this.buildMainPage(manifestData,classes)}/>
            </Switch>
        );
    };
};