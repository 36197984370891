import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withFormsy} from 'formsy-react';
import './OCVFormComponents.css';

export default withFormsy(function OCVTextArea(props: any) {
    const changeValue = (event: {currentTarget: {value: any}}) => {
        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        // Important: Don't skip this step. This pattern is required
        // for Formsy to work.
        props.setValue(event.currentTarget.value);
    };

    const {label, getValue, getErrorMessage} = props;
    return (
        <div>
            <div className='OCVFormComponentsDiv'>
                <TextField
                    label={label}
                    onChange={changeValue}
                    fullWidth
                    multiline
                    rows={4}
                    value={getValue() || ''}
                    variant={'outlined'}
                />
                <span className='OCVFormComponentsSpan'>{getErrorMessage()}</span>
            </div>
        </div>
    );
});