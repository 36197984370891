import React from "react";
import {withFormsy} from 'formsy-react';
import "filepond-polyfill";
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import './OCVFormComponents.css';

registerPlugin(FilePondPluginImagePreview);

export default withFormsy(function OCVFileUpload(props: any) {
    const getBase64 = (file: Blob, cb: (arg0: string | ArrayBuffer | null) => void) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            cb(reader.result);
        };
        reader.onerror = function(error) {
            console.log('Error: ', error);
        };
    };

    const {label, setValue, getErrorMessage} = props;
    return (
        <div className='OCVFormComponentsDiv'>
            <FormLabel>{label}</FormLabel>
            <FilePond allowMultiple={true} maxFiles={3} onupdatefiles={(fileItems) => {
                let files:string[] = [];
                for (let i = 0; i < fileItems.length; i++) {
                    getBase64(fileItems[i].file, (result) => {
                        if (typeof result === "string") {
                            files.push(result.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''));
                        }
                    });
                }
                setValue(files);
            }}/>
            <span className='OCVFormComponentsSpan'>{getErrorMessage()}</span>
        </div>
    );
});