import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import {withRouter} from "react-router";
import Swiper from "react-id-swiper";
import * as moment from "moment";
import Paper from "@material-ui/core/Paper/Paper";
import Lightbox from "react-images";
import './OCVBlogDetail.css';

export default withRouter(function OCVBlogDetail(props: any) {
    const [currentImage, setCurrentImage] = useState(0);
    const [lightboxIsOpen, setLightbox] = useState(false);

    const openLightbox = (event: {preventDefault: () => void}, index : number) => {
        event.preventDefault();
        setCurrentImage(index);
        setLightbox(true);
    };
    const closeLightbox = () => {
        setLightbox(false);
    };
    const gotoPrevious = () => {
        setCurrentImage(currentImage - 1);
    };
    const gotoNext = () => {
        setCurrentImage(currentImage + 1);
    };

    const {blogEntries, match} = props;
    let test = blogEntries[match.params.id];
    let images: any[] = [];
    for (let i=0; i < test.images.length; i++) {
        images.push({src: test.images[i].large});
    }
    const params = {
        parallax: true,
        slidesPerView: 1,
        speed: 3000,
        loop: true,
        autoplay: {
            disableOnInteraction: true
        },
        effect: 'fade'
    };
    return (
        <Row>
            <Col className='OCVBlogDetailColTwo' xs={10}>
                <Paper className='OCVBlogDetailPaper'>
                    <h1>{test.title}</h1>
                    {!("date" in test) ? '' : <span className='OCVBlogDetailSpan'><strong>Posted on {moment.unix((test.date !== null) ? test['date']['sec'] : ' ').format('LL')}</strong></span>}
                    <div dangerouslySetInnerHTML={{__html: test.content}} />
                </Paper>
            </Col>
            {(test.images.length) !== 0 ? <Col className='OCVBlogDetailColOne' xs={10} sm={8} md={4}>
                <Swiper {...params}>
                    {test.images.map((image: {large: string, small: string}, index: number) => (
                        <img alt={image.large} onClick={(e) => openLightbox(e, index)} className='OCVBlogDetailImg' src={test.images.length !== 0 ? image.large : ' '} />
                    ))}
                </Swiper>
                <Lightbox onClickNext={gotoNext} onClickPrev={gotoPrevious} backdropClosesModal currentImage={currentImage} onClose={closeLightbox} isOpen={lightboxIsOpen} images={images} />
            </Col>: ""}
        </Row>
    );
});