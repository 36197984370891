import React, {PureComponent} from "react";
import {Row, Col} from "reactstrap";
import Swiper from "react-id-swiper";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './OCVBlogSlider.css';

export default class OCVBlogSlider extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {blogData: null, currentPage: 0};
    };

    async componentDidMount() {
        try {
            const response = await fetch("https://s3.amazonaws.com/myocv/ocvapps/a20336803/public/blog_tourWaterComplex.json", {mode: "cors"});
            const json = await response.json();
            await this.setState({
                blogData: json.reverse()
            });
        } catch (error) {
            console.log(error);
        }
    };

    gotoPrevious() {
        this.setState({
            currentPage: (this.state.currentPage !== 0) ? this.state.currentPage - 1 : 0
        });
    };
    gotoNext() {
        this.setState({
            currentPage: (this.state.currentPage !== this.state.blogData.length-1) ? (this.state.currentPage + 1) : this.state.blogData.length - 1
        });
    };

    renderSlider = (blog: any, currentPage: number) => {
        return <Swiper {...this.params}>
            {blog[currentPage]['images'].map((image: {large: string, small: string}, index: number) => (
                <img className='OCVBlogSliderImg' alt={image.large} key={index} src={image.large} />
            ))}
        </Swiper>
    };
    params = {
        parallax: true,
        slidesPerView: 1,
        effect: 'fade'
    };
    render() {
        return (
            (this.state.blogData !== null) ? <Row>
                {(this.state.blogData[this.state.currentPage]['images'].length !== 0) ? <Col style={{padding: "0"}} xs={12} sm={12} md={7}>
                    {this.renderSlider(this.state.blogData, this.state.currentPage)}
                </Col> : ''}
                <Col xs={12} sm={12} md={5}>
                    <div>
                        <IconButton aria-label="Close" className='OCVBlogSliderButton' onClick={() => this.gotoPrevious()}>
                            <FontAwesomeIcon icon={["fas", "chevron-left"]}/>
                        </IconButton>
                        <span style={{
                            height: '25px',
                            padding: '0 10px'
                        }}>{(this.state.currentPage + 1) + " of " + this.state.blogData.length}</span>
                        <IconButton aria-label="Close" className='OCVBlogSliderButton' onClick={() => this.gotoNext()}>
                            <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
                        </IconButton>
                    </div>
                    <h4>{(this.state.blogData.length !== 0) ? this.state.blogData[this.state.currentPage].title: ""}</h4>
                    <p dangerouslySetInnerHTML={{__html: (this.state.blogData.length !== 0) ? this.state.blogData[this.state.currentPage].content: ""}}/>
                </Col>
            </Row> : ""
        );
    };
};