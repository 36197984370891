import React, {useState} from 'react';
import {stack as Menu} from 'react-burger-menu';
import {Row, Col} from 'reactstrap';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {NavLink, Link} from "react-router-dom";
import NavSubmenu from "../NavSubmenu/NavSubmenu";
import OCVVideoPlayer from "../../OCVFeatures/OCVVideoPlayer/OCVVideoPlayer";
import FacebookWidget from "../FacebookWidget";
import TwitterWidget from "../TwitterWidget";
import OCVBlogSlider from "../../OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider";
import HorizontalSublayout from "../HorizontalSublayout/HorizontalSublayout";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import IconButton from "@material-ui/core/IconButton/IconButton";
import ReactModal from "react-modal";
import './NavBar.css';

ReactModal.setAppElement('#root');

export default function Navbar(props: any) {
    const [showModal, setShowModal] = useState(false);
    const [modalView, setModalView] = useState(Object.keys(props.manifestData['views'])[0]);
    const [modalTitle, setModalTitle] = useState("");
    
    const handleOpenModal = (view: string, title: string) => {
        setShowModal(true);
        setModalView(view);
        setModalTitle(title);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getView = (view: string, manifestData: any, classes: any) => {
        const manifestDataViews = manifestData['views'][view];
        const manifestDataViewConfig = manifestDataViews["config"];

        switch (manifestDataViews['type']) {
            case 'imageView':
                return <img className={"NavBarImg img-fluid"} src={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[manifestDataViewConfig['image']]['url'] : ''} alt={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[manifestDataViewConfig['image']]['altText'] : ''} />;
            case 'button':
                return <div style={{
                    paddingTop: (manifestDataViewConfig["paddingTop"]) ? manifestDataViewConfig["paddingTop"] : 0, paddingBottom: (manifestDataViewConfig["paddingBottom"]) ? manifestDataViewConfig["paddingBottom"] : 0,
                    paddingLeft: (manifestDataViewConfig["paddingLeft"]) ? manifestDataViewConfig["paddingLeft"] : 0, paddingRight: (manifestDataViewConfig["paddingRight"]) ? manifestDataViewConfig["paddingRight"] : 0,}}>
                    {(manifestData['features'][manifestDataViewConfig['feature']]['type'] === "modal") ? <button style={{
                            background: (manifestDataViewConfig['backgroundColor']) ? manifestDataViewConfig['backgroundColor'] : manifestData["stylesheet"]["colors"]["primary"],
                        }} type={"button"} onClick={() => handleOpenModal(manifestData['features'][manifestDataViewConfig['feature']]['view'],manifestData['features'][manifestDataViewConfig['feature']]['title'])} className={classes.homepageButton}>{manifestDataViewConfig['title']}</button>
                        : (manifestData['features'][manifestDataViewConfig['feature']]['type'] !== "webview" || !manifestData['features'][manifestDataViewConfig['feature']]['subtype'].includes('external')) ? <Link to={manifestDataViewConfig['feature']} >
                            <button style={{
                                background: (manifestDataViewConfig['backgroundColor']) ? manifestDataViewConfig['backgroundColor'] : manifestData["stylesheet"]["colors"]["primary"],
                            }} type={"button"} className={classes.homepageButton}>{manifestDataViewConfig['title']}</button>
                        </Link> : <a rel="noopener noreferrer" target={"_blank"} href={manifestData['features'][manifestDataViewConfig['feature']]['url']}>
                            <button style={{
                                background: (manifestDataViewConfig['backgroundColor']) ? manifestDataViewConfig['backgroundColor'] : manifestData["stylesheet"]["colors"]["primary"],
                            }} type={"button"} className={classes.homepageButton}>{manifestDataViewConfig['title']}</button>
                        </a>
                    }
                </div>;
            case 'label':
                return <p dangerouslySetInnerHTML={{__html: manifestDataViewConfig['text']}} />;
            case "horizontalLayout":
                return <HorizontalSublayout classes={classes} manifestData={manifestData} config={manifestDataViewConfig} />;
            case "video":
                return <OCVVideoPlayer config={manifestDataViewConfig} />;
            case "facebookWidget":
                return <FacebookWidget config={manifestDataViewConfig} />;
            case "twitterWidget":
                return <TwitterWidget config={manifestDataViewConfig} />;
            case "blogSlider":
                return <OCVBlogSlider link={manifestData['features'][manifestDataViewConfig['feature']]['url']} />;
        };
    };

    const {manifestData, navbarConfig, classes} = props;
    const menuStyles = {
        bmMenuWrap: {
            top: '0'
        },
        bmItem: {
            display: 'inline-block'
        },
        bmBurgerButton: {
            position: 'absolute',
            width: '36px',
            height: '30px',
            left: 'initial',
            right: '36px',
            margin: '20px 0px'
        },
        bmBurgerBars: {
            background: (manifestData.theme === 'dark') ? 'white' : '#000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenu: {
            background: manifestData.stylesheet.colors.primary,
            padding: '2.5em 0 0',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: manifestData.stylesheet.colors.tertiary,
            padding: '0.8em'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0)',
            position: 'fixed',
            top: '0'
        }
    };
    const navItems = (navbarConfig['items'] !== null && manifestData !== null) ? navbarConfig['items'].map((item: string) => (
            (manifestData['features'][item]['type'] !== null && manifestData['features'][item]['type'] !== "submenu")
                ? (<ListItem style={{width: (navbarConfig.emphasis === item) ? "220px" : "auto", }} key={item} className={(navbarConfig.emphasis === item) ? classes.navButton : classes.navListItem}>
                    {(manifestData['features'][item]['type'] !== null && manifestData['features'][item]['type'] === "webview" && !manifestData['features'][item]['subtype'].includes("internal"))
                            ? <a style={{margin: (navbarConfig.emphasis === item) ? "0 auto": "0"}} rel="noopener noreferrer" target={"_blank"} className={(navbarConfig.emphasis === item) ? classes.navButtonLink : classes.navItemLink} href={manifestData['features'][item]['url']}>{manifestData['features'][item]['title']}</a>
                            : (manifestData['features'][item]['type'] === 'modal') ? <span className={classes.navItemLink} onClick={() => handleOpenModal(manifestData['features'][item]['view'], manifestData['features'][item]['title'])}>{manifestData['features'][item].title}</span>: <NavLink to={'/' + item} className={classes.navItemLink}>{manifestData['features'][item].title}</NavLink>
                            //<NavLink style={{margin: (navbarConfig.emphasis === item) ? "0 auto": "0"}} className={(navbarConfig.emphasis === item) ? classes.navButtonLink : classes.navItemLink} to={'/'+item}>{manifestData['features'][item]['title']}</NavLink>
                    }
                </ListItem>)
                : (<ListItem key={item} className={'NavBarListItem ' + classes.navListItem}><NavSubmenu title={manifestData['features'][item]['title']} classes={classes} items={ manifestData['features'][item].items} manifestData={manifestData}/></ListItem>)
    )) : '';
    return(
        <>
            <Row className={classes.navbar}>
                <Col xs ={4} sm={(navbarConfig['subtype'] === "longLogo") ? 2 : 1}>
                    <NavLink to={"/"} className={classes.logoContainer}>
                        <img className={(navbarConfig['subtype'] === "longLogo") ? classes.longLogo : classes.logo} src={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[navbarConfig.icon]['url'] : ''} alt={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[navbarConfig.icon]['altText'] : ''}/>
                    </NavLink>
                </Col>
                <Col className={"d-lg-none"} xs={6} sm={9}>
                    <Menu styles={menuStyles} burgerButtonClassName={classes.bmBurgerButton} burgerBarClassName={classes.bmBurgerBars} crossButtonClassName={classes.bmCrossButton}
                            crossClassName={classes.bmCross} menuClassName={classes.bmMenu} morphShapeClassName={classes.bmMorphShape} itemListClassName={classes.bmItemList} overlayClassName={classes.bmOverlay}
                            right={true}>
                        <List className={classes.navList}>
                            {(navbarConfig['includeHome']) ? <ListItem className={classes.navListItem}><NavLink to={"/"} className={classes.navItemLink}>Home</NavLink></ListItem> : ""}
                            {navItems}
                        </List>
                    </Menu>
                </Col>
                <Col className={"d-none d-lg-block"} xs={6} sm={10}>
                    <List className={classes.navList}>
                        {(navbarConfig['includeHome']) ? <ListItem className={classes.navListItem}><NavLink to={"/"} className={classes.navItemLink}>Home</NavLink></ListItem> : ""}
                        {navItems}
                    </List>
                </Col>
            </Row>
            <ReactModal className='NavBarModalContent' overlayClassName='NavBarModal' contentLabel="Minimal Modal Example" onRequestClose={() => handleCloseModal()} isOpen={showModal}>
                <div className='NavBarDiv'>
                    <h4 className="NavBarModalTitle">{modalTitle}</h4>
                    <IconButton aria-label="Close" className='NavBarCloseButton' onClick={() => handleCloseModal()}>
                        <FontAwesomeIcon className="NavBarIcon" icon={["fas", "times"]}/>
                    </IconButton>
                </div>
                {getView(modalView, manifestData, classes)}
            </ReactModal>
        </>
    );
};