import React, {Component} from "react";

interface Props {
    totalRecords: number,
    pageLimit: number,
    pageNeighbours: number,
    onPageChanged(data: any) : void;
};

interface State {
    totalRecords: number;
    pageLimit: number;
    pageNeighbours: number;
    currentPage: number;
};

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (start: number, to: number, step = 1) => {
    let i = start;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

export default class Pagination extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            pageLimit :  5,
            totalRecords :  0,
            pageNeighbours : 0,
            currentPage: 1
        };
    };

    componentDidMount() {
        const {totalRecords, pageLimit, pageNeighbours} = this.props;
        this.setState({totalRecords, pageLimit, pageNeighbours});
        this.gotoPage(1);
    };
    componentDidUpdate(prevProps: {totalRecords: number}) {
        if(this.props.totalRecords !== prevProps.totalRecords) {
            this.setState({
                totalRecords: this.props.totalRecords
            });
            this.gotoPage(1);
        }
    };

    gotoPage = (page: number) => {
        const {onPageChanged = (f: any) => f, totalRecords, pageLimit} = this.props;
        const currentPage = Math.max(0, Math.min(page, Math.ceil(totalRecords / pageLimit)));
        const paginationData = {
            currentPage,
            totalPages: Math.ceil(totalRecords / pageLimit),
            pageLimit: pageLimit,
            totalRecords: totalRecords
        };
        window.scrollTo(0, 0);
        this.setState({currentPage}, () => onPageChanged(paginationData));
    };

    handleClick = (page: number, evt: {preventDefault: () => void}) => {
        evt.preventDefault();
        this.gotoPage(page);
    };
    handleMoveLeft = (evt: {preventDefault: () => void}) => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - this.state.pageNeighbours * 2 - 1);
    };
    handleMoveRight = (evt: {preventDefault: () => void}) => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + this.state.pageNeighbours * 2 + 1);
    };

    fetchPageNumbers = (totalRecords: number, pageLimit: number, pageNeighbours: number) => {
        const totalPages = Math.ceil(totalRecords / pageLimit);
        const currentPage = this.state.currentPage;
        const totalNumbers = pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages: any[];

            const leftBound = currentPage - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    render() {
        const {currentPage} = this.state;
        const {totalRecords, pageLimit, pageNeighbours} = this.props;
        const pages = this.fetchPageNumbers(totalRecords, pageLimit, pageNeighbours);
        return (
            <>
                <nav style={{padding: '10px'}} aria-label="OCV Pagination">
                    <ul className="pagination">
                        {pages.map((page, index) => {
                            if (page === LEFT_PAGE)
                                return (
                                    <li key={index} className="page-item">
                                        <a
                                            className="page-link"
                                            href="/"
                                            aria-label="Previous"
                                            onClick={this.handleMoveLeft}
                                        >
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                );

                            if (page === RIGHT_PAGE)
                                return (
                                    <li key={index} className="page-item">
                                        <a
                                            className="page-link"
                                            href="/"
                                            aria-label="Next"
                                            onClick={this.handleMoveRight}
                                        >
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                );

                            return (
                                <li
                                    key={index}
                                    className={`page-item${
                                        currentPage === page ? " active" : ""
                                    }`}
                                >
                                    <a
                                        className="page-link"
                                        href="/"
                                        onClick={e => this.handleClick(page, e)}
                                    >{page}</a>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </>
        );
    };
};