import React, {useState, useEffect, useRef} from "react";
import {GoogleMap, Marker, OverlayView, withGoogleMap, withScriptjs} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import pinBlack from "../../pin/pinblack.png";
import pinBlue from "../../pin/pinblue.png";
import pinBrown from "../../pin/pinbrown.png";
import pinGray from "../../pin/pingray.png";
import pinGreen from "../../pin/pingreen.png";
import pinOrange from "../../pin/pinorange.png";
import pinPink from "../../pin/pinpink.png";
import pinPurple from "../../pin/pinpurple.png";
import pinRed from "../../pin/pinred.png";
import pinYellow from "../../pin/pinyellow.png";
import './OCVMapView.css';

export default withScriptjs(withGoogleMap(function OCVMapView(props: any) {
    let _map = useRef<any>();
    const [center, setCenter] = useState({lat: 32.6099, lng: -85.4808});
    const [zoom, setZoom] = useState(6);
    const [bounds, setBounds] = useState(new google.maps.LatLngBounds());

    useEffect(() => {
        if (!props.subtypes.includes("cluster")) {
            rebuildMarkers(props.locations);
        }
        rebuildMarkers(props.locations);
    }, [props.subtypes, props.locations])

    const rebuildMarkers = (markers: [any]) => {
        let newBounds = new google.maps.LatLngBounds();
        if (markers.length) {
            markers.map((marker: any) => (marker.latitude !== "0" && marker.longitude !== "0") ? newBounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude)): "");
        }
        if (newBounds && _map && _map.current.fitBounds) {
            _map.current.fitBounds(newBounds);
        }
        const newBoundsCenter = newBounds.getCenter();
        const newCenter = {
            lat: newBoundsCenter.lat(),
            lng: newBoundsCenter.lng()
        };
        setCenter(newCenter);
        setZoom(_map.current.getZoom());
        setBounds(newBounds);
    };
    const getIcon = (color: string) => {
        switch (color) {
            case "black":
                return pinBlack;
            case "blue":
                return pinBlue;
            case "brown":
                return pinBrown;
            case "gray":
                return pinGray;
            case "green":
                return pinGreen;
            case "orange":
                return pinOrange;
            case "pink":
                return pinPink;
            case "purple":
                return pinPurple;
            case "red":
                return pinRed;
            case "yellow":
                return pinYellow;
            default:
                return pinRed;
        };
    };

    const {subtypes, locations, showOverlayView, isFiltering} = props;
    return (
        <div onLoad={(!subtypes.includes("cluster")) ? () => rebuildMarkers(locations) : () => ""}>
            <GoogleMap
                ref={(c) => _map.current = c}
                defaultCenter={center}
                defaultOptions={{
                    scrollwheel: true,
                    gestureHandling: "auto"
                }}>
                {(showOverlayView) ? <OverlayView bounds={bounds}>
                    <div className='OCVMapViewDiv'>
                        <h4>No Markers fit your filter.</h4>
                    </div>
                </OverlayView> : ""}
                {(subtypes.includes("cluster") && !isFiltering)
                    ? <MarkerClusterer
                        zoomOnClick={true}
                        enableRetinaIcons>
                        {locations.map((location:any, index: any) => (
                            (location.latitude !== "0"  && location.longitude !== "0") ? <Marker icon={getIcon(location["type"]["pin"])} key={index} position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }} /> : ""
                        ))}
                    </MarkerClusterer>
                    : locations.map((location:any, index: any) => (
                        (location.latitude !== "0"  && location.longitude !== "0") ? <Marker icon={getIcon(location["type"]["pin"])} key={index} position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }} /> : ""
                    ))}
            </GoogleMap>
        </div>
    );
}));