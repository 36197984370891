import React from "react";
import {Row, Col} from "reactstrap";
import Paper from "@material-ui/core/Paper/Paper";
import './OCVContactsEntry.css';
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function OCVContactsEntry(props: any) {const {classes, entry} = props;
    return (
        <Paper className='OCVContactsEntryPaper'>
            <Row className={classes.contactsEntry}>
                {("image" in entry) ? (
                <Col className={classes.contactsImageSection} xs={12} sm={12} md={10}>
                    <div className={classes.contactsImageContainer}>
                        <img alt={entry.image} className={classes.contactsImage} width={100} height={100} src={(entry.image === null) ? "" : entry.image} />
                    </div>
                    {/* <div className={classes.contactsSocialContainer}>
                        <ul className={classes.contactsSocialList}>
                            <li className={classes.contactsSocialListItem}>
                                <a className={classes.contactsSocialLink}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', 'facebook']} />
                                </a>
                            </li>
                            <li className={classes.contactsSocialListItem}>
                                <a className={classes.contactsSocialLink}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', 'linkedin']} />
                                </a>
                            </li>
                            <li className={classes.contactsSocialListItem}>
                                <a className={classes.contactsSocialLink}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', 'instagram']} />
                                </a>
                            </li>
                            <li className={classes.contactsSocialListItem}>
                                <a className={classes.contactsSocialLink}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', 'twitter']} />
                                </a>
                            </li>
                        </ul>
                    </div>*/}
                </Col>) : ""}
                <Col className={classes.contactsTextSection} xs={12} sm={12} md={10}>
                    <h3 className={classes.contactsTextTitle}>{entry.title}</h3>
                    {("jobtitle" in entry) ? <p className={classes.contactsTextContent}>{entry.jobtitle}</p> : ""}
                    <p className={classes.contactsTextContent}>
                        {("address" in entry) ? <a className='OCVContactsEntryAddress' href={"http://maps.google.com/?q=" + entry.address}>{entry.address}</a> : ""}
                        {("email" in entry) ? (<span> Email: <a href={"mailto:" + entry.email}>{entry.email}</a><br/></span>) : ""}
                        {("phone" in entry) ? (<span> Phone: <a href={"tel:" + entry.phone }>{entry.phone}</a><br/></span>) : ""}
                        {("fax" in entry) ? (<span>Fax: <a href={"tel:334-456-7890"}>334-456-7890</a><br/></span>) : ""}
                        {("website" in entry) ? (<span><a href={entry.website}>Visit Website</a></span>) : ""}
                    </p>
                    {("description" in entry) ? <p className={classes.contactsTextContent}>{entry.description}</p> : ""}
                </Col>
            </Row>
        </Paper>
    );
};