import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import OCVBlogContainer from "./OCVBlogContainer/OCVBlogContainer";
import OCVBlogDetail from "./OCVBlogDetail/OCVBlogDetail";

export default withRouter(class OCVBlog extends Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            blogData: [],
            blogLink: this.props.link,
            classes: this.props.classes,
            route : this.props.route,
            isLoading: true
        };
    };

    componentDidMount() {
        fetch(this.state.blogLink, {mode: 'cors'})
            .then(response => response.json())
            .then( data => {
                this.setState({
                    blogData: data,
                    isLoading: (data !== null),
                });
            });
    };

    componentDidUpdate(prevProps: { blogLink: any; }) {
        if( prevProps.blogLink !== this.props.link) {
            try {
                fetch(this.state.blogLink, {mode: 'cors'})
                    .then(response => response.json())
                    .then( data => {
                        this.setState({
                            blogData: data,
                            isLoading: false
                        })
                    })
            } catch (e) {};
        }
    };

    render() {
        return(
            <Switch>
                <Route exact path="/" render={() => (<Redirect to="/"/>)}/>
                <Route exact path={'/'+this.state.route} render={()=> <OCVBlogContainer submitATipLink={this.props.submitATipLink} title={this.props.title} link={this.state.blogLink} classes={this.state.classes} route={this.state.route} blogData={this.state.blogData} perPage={5}/>} />
                {(this.state.blogData.length !== 0) ? <Route path={'/' + this.state.route+ '/:id'} render={() => <OCVBlogDetail blogEntries={(this.state.blogData.length !== 0) ? this.state.blogData : [] } />}/> : ' '}
            </Switch>
        )
    };
});