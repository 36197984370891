import React, {useEffect} from "react";
import {Col, Row} from "reactstrap";
import Paper from "@material-ui/core/Paper/Paper";
import './GoogleSearch.css';

export default function GoogleSearch(props: any) {
    useEffect(() => {
        (function() {
            var cx = '013059541663991809410:aprwrpnng28';
            var gcse = document.createElement('script');
            gcse.type = 'text/javascript';
            gcse.async = true;
            gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
            var s = document.getElementsByTagName('script')[0];
            // @ts-ignore
            s.parentNode.insertBefore(gcse, s);
        })();
    });
    return (
        <Row>
            <Col className={"GoogleSearchCol align-items-center"} xs={10}>
                <Paper className='GoogleSearchPaper'>
                    <div className="gcse-searchbox" data-as_sitesearch="http://ocv-web-playground.s3-website-us-east-1.amazonaws.com/" />
                    <div className="gcse-searchresults" />
                </Paper>
            </Col>
        </Row>
    );
};