import React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import {Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './OCVMapDetailView.css';

export default function OCVMapDetailView(props: any) {
    const {leaveDetail, selectedLocation} = props;
    return (
        <Paper className='OCVMapDetailViewPaper'>
            <button className={"btn btn-primary"} onClick={leaveDetail}>Back to List</button>
            <h2>{selectedLocation.title}</h2>
            <h6 className='OCVMapDetailViewH6'><i>{selectedLocation.subtitle}</i></h6>
            {(selectedLocation.facebookLink) ? <a className='OCVMapDetailViewAnchor' target={"_blank"} rel="noopener noreferrer" href={selectedLocation.facebookLink}><FontAwesomeIcon icon={["fab", "facebook"]}/></a> : ""}
            {(selectedLocation.twitterHandle) ? <a className='OCVMapDetailViewAnchor' target={"_blank"} rel="noopener noreferrer" href={"https://twitter.com/" + selectedLocation.twitterHandle}><FontAwesomeIcon icon={["fab", "twitter"]}/></a> : ""}
            {(selectedLocation.address) ? <Col className='OCVMapDetailViewCol' xs={12} sm={12} md={6}><h6>Location</h6><div>{selectedLocation.address}</div><a target={"_blank"} rel="noopener noreferrer" className="OCVMapDetailLinks" href={"http://maps.google.com/?q="+ selectedLocation.address}>Directions</a></Col> : ""}
            {(selectedLocation.description) ? <p className='OCVMapDetailViewParagraph' dangerouslySetInnerHTML={{__html: selectedLocation.description}} /> : ""}
            <Row className='OCVMapDetailViewRow'>
                {(selectedLocation.links.length !== 0) ? <Col className='OCVMapDetailViewCol' xs={12} sm={12} md={6}><h6>Links</h6>{selectedLocation.links.map((link: any) => (<div><a className="OCVMapDetailLinks" href={link.link}>{link.name}</a></div>))}</Col> : ""}
                {(selectedLocation.email.length !== 0) ? <Col className='OCVMapDetailViewCol' xs={12} sm={12} md={6}><h6>Email</h6>{selectedLocation.email.map((email: any) => (<div><a className="OCVMapDetailLinks" href={"mailto:" + email.email}>{email.name}</a></div>))}</Col> : ""}
                {(selectedLocation.phone.length !== 0) ? <Col className='OCVMapDetailViewCol' xs={12} sm={12} md={6}><h6>Phone</h6>{selectedLocation.phone.map((phone: any) => (<div><a className="OCVMapDetailLinks" href={"tel:" + phone.number}>{phone.name}</a></div>))}</Col> : ""}
                {(selectedLocation.tags.length !== 0) ? <Col className='OCVMapDetailViewCol' xs={12} sm={12} md={6}><h6>Tags</h6>{selectedLocation.tags.map((tag: any, index: number) => (<span>{(index === selectedLocation.tags.length - 1) ? tag.name : tag.name + ", "}</span>))}</Col> : ""}
            </Row>
        </Paper>
    );
};