import React from "react";
import Feature from "./Feature/Feature";
import {Row} from 'reactstrap';
import './FeatureBar.css';

export default function FeatureBar(props: any) {
    const {featureBarConfig, features, manifestData, classes} = props;
    let featureItems = featureBarConfig.items.map((item: any) => (
        (features && features[item.feature])
            ? <Feature manifestData={manifestData} bg={featureBarConfig.bg} url={(features[item.feature]['url'] !== null) ? features[item.feature]['url'] : ""} subtype={(features[item.feature]['subtype'] !== null) ? features[item.feature].subtype : []} image={(item.image !== null) ? item.image : ""} type={features[item.feature].type} feature={item.feature} key={item.title} classes={classes} width={item.width} height={item.height} color={featureBarConfig.itemColor} iconType={item.icon} title={item.title}/>
                : ""
    ));
    return <Row className={classes.featureBar}><div className='featureBarDiv'>{featureItems}</div></Row>;
};