import React, {useState, useEffect} from 'react';
import {withFormsy} from 'formsy-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField/TextField";
import './OCVFormComponents.css';

export default withFormsy(function OCVDateTimePicker(props: any) {
    const [value, setValue] = useState(props.defaultValue);
    const {showTimeOnly, showTime, format, placeholderText, getErrorMessage, defaultValue} = props;

    const changeValue = (date: any) => {
        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        // Important: Don't skip this step. This pattern is required
        // for Formsy to work.
        props.setValue(date);
        setValue(date);
    };
    
    useEffect(() => {
        props.setValue(defaultValue);
    }, [defaultValue]);

    const customInput = <TextField
        label={props.label}
        onChange={changeValue}
        fullWidth={true}
        variant={'outlined'}
    />;
    return (
        <div className='OCVFormComponentsDiv'>
            <DatePicker
                selected={value}
                onChange={changeValue}
                showTimeSelect={showTime}
                showTimeSelectOnly={showTimeOnly}
                customInput={customInput}
                timeFormat="HH:mm"
                timeIntervals={1}
                dateFormat={format}
                timeCaption="Time"
                placeholderText={placeholderText}
            />
            <span className='OCVFormComponentsSpan'>{getErrorMessage()}</span>
        </div>
    );
});