import React from 'react';
import {Row, Col} from 'reactstrap';
import ocv from "../../../assets/OCV_Official.png";
import sheriff from "../../../assets/Sheriff_White.png";
import ema from "../../../assets/Ema_logo_white.png";
import fire from "../../../assets/Fire_Long_V2.png";
import police from "../../../assets/Police_Long.png";
import publicHealth from "../../../assets/Public_Health_Long.png";
import {NavLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import List from "@material-ui/core/List/List";
import {IconName} from "@fortawesome/fontawesome-svg-core";
import './Footer.css';

interface IVerticalMarket {
    [id: string]: {
        [id2: string]: any
    };
}

export default function Footer(props: any) { // modify to include [footerConfig.vertical] and not just title
    const {classes, manifestData, footerConfig} = props;
    let verticalMarkets: IVerticalMarket = {
        sheriff: {
            href: "https://thesheriffapp.com", // dark option to be handled elsewhere
            alt: "The Sheriff App",
            img: sheriff
        },
        police: {
            href: "https://thepoliceapp.com",
            alt: "The Police App",
            img: police
        },
        ema: {
            href: "https://myemaapp.com",
            alt: "My EMA App",
            img: ema
        },
        fire: {
            href: "https://thefiredepartmentapp.com",
            alt: "The Fire Department App",
            img: fire
        },
        health:  {
            href: "https://thepublichealthapp.com",
            alt: "The Public Health App",
            img: publicHealth
        },
        ocv: {
            href: "https://ocvapps.com",
            alt: "OCV Apps",
            img: ocv
        }
    };

    const SimpleFooter = (classes: any, manifestData: any, footerConfig: any) => (
        <Row className={classes.footer}>
            <Col lg={1} md={3}>
                <img className={classes.footerIcon} src={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[footerConfig.icon]['url'] : ''} alt={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[footerConfig.icon]['altText'] : ''} />
            </Col>
            <Col className={classes.columnA} style={{paddingLeft: 0, paddingRight: 0}} lg={6} md={5}>
                <div className={classes.footerInfo}>
                    <h2 className='footerInfo'>{footerConfig.title}</h2>
                    <h5 className='footerInfo' dangerouslySetInnerHTML={{__html:footerConfig.address }} />
                    <h5 className='footerInfo'>Phone: {footerConfig.phone}</h5>
                </div>
            </Col>
            <Col className='columnB' style={{paddingLeft: '20px', paddingRight: '20px'}} lg={5} md={4} sm={12}>
                <div className={classes.footerNav}>
                    {footerConfig["menuItems"].map((item: string, index: number) => (
                        (index !== footerConfig["menuItems"].length - 1)
                            ? <NavLink key={item+index} className={classes.footerNavItem} to={"/" + item}>{manifestData["features"][item]["title"] + " / "}</NavLink>
                            : <NavLink key={item+index} className={classes.footerNavItem} to={"/" + item}>{manifestData["features"][item]["title"]}</NavLink>
                    ))}
                    <br/>
                    <h6 className='poweredBy'>Powered by</h6>
                    <a rel="noopener noreferrer" href={verticalMarkets[footerConfig.vertical].href} target={"_blank"}>
                        <img className='footerImgOne' src={verticalMarkets[footerConfig.vertical].img} alt={footerConfig.vertical.alt} />
                    </a>
                </div>
            </Col>
        </Row>
    );
    const ComplexFooter = (classes: any, manifestData: any, footerConfig: any) => (
        <div>
            <Row className='complexFooterRowOne' style={{background: manifestData['stylesheet']['colors'][footerConfig['phoneNumberCardBackground']]}}>
                <Col xs={10} key="col1">
                    <Row>
                        {footerConfig["phoneNumberCard"].map((phone: any, index: any) => (
                            <Col key={phone.title + index} xs={12} sm={12} md={6}>
                                <div className='phoneNumberCardDiv'>
                                    <h4>{phone.title}</h4>
                                    {phone["items"].map((item: any, index: any) => (
                                        <p key={item.name + index} className='footerParagraph'>{item.name + " - "}<strong><a className='footerAnchorOne' href={"tel:" + item.number}>{item.number}</a></strong></p>
                                    ))}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col xs={12} key="col2">
                    <div className='col2div'>
                        <Row>
                            {footerConfig["menuItems"].map((item: string, index: number) => (
                                <>
                                    <Col className={"footerColOne d-lg-none"} sm={6} md={4}><NavLink key={item + index} className='footerNavLink' to={"/" + item}>{manifestData["features"][item]["title"]}</NavLink></Col>
                                    <NavLink className={"footerNavLink d-none d-lg-block"} key={item + index} to={"/" + item}>{manifestData["features"][item]["title"]}</NavLink>
                                </>
                            ))}
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className={'complexFooterRowTwo ' + classes.footer}>
                <img className='footerImgTwo' src={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[footerConfig.icon]['url'] : ''} alt={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[footerConfig.icon]['altText'] : ''}/>
                <Col className='footerColTwo' xs={10}>
                    <h4 style={{color: (manifestData["theme"] === "dark") ? "#fff" : "#000", letterSpacing: "1px", fontSize: "1.3em"}}>{footerConfig.title}</h4>
                    <span dangerouslySetInnerHTML={{__html:footerConfig.address }}/>
                    <a className='footerAnchorTwo' style={{color: (manifestData["theme"] === "dark") ? "#fff" : "#000"}} href={"tel:" + footerConfig.phone}>{footerConfig.phone}</a>
                    <p>Hours: {footerConfig.businessHours}</p>
                    <List className='footerList'>
                        {footerConfig['socialMedia'].map((item: {type: IconName; link: string | undefined; }) => (
                            <ListItem key={item.type} className={classes.footerSocialListItem}>
                                <a rel="noopener noreferrer" target={"_blank"} aria-label={item.type} className={classes.topSocialLink} href={item.link}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', item.type]} />
                                </a>
                            </ListItem>
                        ))}
                    </List>
                    <h6 className='footerH6'>Copyright &copy;{new Date().getFullYear()}. Powered by</h6>
                    <a rel="noopener noreferrer" href={verticalMarkets[footerConfig.vertical] ? verticalMarkets[footerConfig.vertical].href : verticalMarkets['ocv'].href} target={"_blank"}>
                        <img className='footerImgOne' src={verticalMarkets[footerConfig.vertical] ? verticalMarkets[footerConfig.vertical].img : verticalMarkets['ocv'].img} alt={footerConfig.vertical.alt} />
                    </a>
                </Col>
            </Row>
        </div>
    );

    return(
        <div>
            {(footerConfig["subtype"] === "simple") && SimpleFooter(classes, manifestData, footerConfig)}
            {(footerConfig["subtype"] === "complex") && ComplexFooter(classes, manifestData, footerConfig)}
        </div>
    );
};