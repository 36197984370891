import React, {useState} from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Pagination from "../../../../MainComponents/Pagination";
import './OCVMapList.css';

export default function OCVMapList(props: any) {
    const [currentEntries, setCurrentEntries] = useState(props.locations.slice(0,5));
    const onPageChanged = (data: {currentPage: number, totalPages: number, pageLimit: number}) => {
        const {currentPage, totalPages, pageLimit} = data;
        const {locations} = props;
        const offset = (currentPage - 1) * pageLimit;
        const currentEntries = locations.slice(offset, offset + pageLimit);
        setCurrentEntries({currentPage, currentEntries, totalPages});
    };

    const {locations, onSelectedEntry} = props;
    return (
        <>
            <div className='OCVMapListDiv'>
                {currentEntries.map((location: any, index: string | number | undefined) => (
                    <Paper onClick={() => onSelectedEntry(location)} className='OCVMapListPaper' key={index}>
                        <h3>{location.title}</h3>
                        <h6 className='OCVMapListH6'><i>{location.subtitle}</i></h6>
                        <p>{location["sort"].value}</p>
                        <div>{location.address}</div>
                        <a target={"_blank"} rel="noopener noreferrer" href={"http://maps.google.com/?q=" + location.address}>Directions</a>
                    </Paper>
                ))}
            </div>
            <Pagination
                totalRecords={locations.length}
                pageLimit={12}
                pageNeighbours={0}
                onPageChanged={onPageChanged}
            />
        </>
    );
};