import React from "react";
import {Col, Row} from "reactstrap";
import Paper from "@material-ui/core/Paper/Paper";
import OCVIcon from "../../OCVFeatures/OCVIcon";
import ios from "../../../assets/app-store-badge.svg";
import google from "../../../assets/google-play-badge.png";
import './ViewOurApp.css';

export default function ViewOurApp(props: any) {
    const {classes, config, manifest} = props;
    return (
        <Row>
            <Col className={"ViewOurAppColOne align-items-center"} xs={10}>
                <Paper className='ViewOurAppPaperOne'>
                    <img className='ViewOurAppImgOne' src={(manifest['stylesheet'] && manifest['stylesheet'].images) ? manifest['stylesheet'].images[config.icon]['url'] : ''} alt={(manifest['stylesheet'] && manifest['stylesheet'].images) ? manifest['stylesheet'].images[config.icon]['altText'] : ''} height={100} width={100} />
                    <h1 className='ViewOurAppH1'>{config.appTitle}</h1>
                    <p dangerouslySetInnerHTML={{__html: config.appDescription}} />
                    <Row>
                        <Col className='ViewOurAppColTwo' xs={10}>
                            <a href={config.iosLink} rel="noopener noreferrer" target={"_blank"} className='ViewOurAppAnchor'><img className='ViewOurAppBadgeStyle' alt="ios" src={ios} /></a>
                            <a href={config.androidLink} rel="noopener noreferrer" target={"_blank"} className='ViewOurAppAnchor'><img className='ViewOurAppBadgeStyle' alt="google" src={google} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={10} sm={10} md={12} lg={{size: "4", order: "2"}}>
                            <img className={"ViewOurAppImgTwo img-fluid"} src={(manifest['stylesheet'] && manifest['stylesheet'].images) ? manifest['stylesheet'].images[config.screenshot]['url'] : ''} alt={(manifest['stylesheet'] && manifest['stylesheet'].images) ? manifest['stylesheet'].images[config.screenshot]['altText'] : ''} width={325} height={638} />
                        </Col>
                        <Col className={"align-items-center"} xs={10} sm={10} md={5} lg={{size: "4", order: "1"}}>
                            {config.leftFeatures.map((feature: {icon: any, title: React.ReactNode, description: React.ReactNode}) => (
                                <div key={feature.icon}>
                                    <Paper className='ViewOurAppPaperTwo'>
                                        <OCVIcon style={{display:"inline", padding: "5px"}} classes={classes} width={"50"} height={"50"} color={"#1e824c"} type={feature.icon} />
                                        <h5 className='ViewOurAppH5'>{feature.title}</h5>
                                        <p>{feature.description}</p>
                                    </Paper>
                                </div>
                            ))}
                        </Col>
                        <Col className={"align-items-center"} xs={10} sm={10} md={5} lg={{size: "4", order: "3"}}>
                            {config.rightFeatures.map((feature: {icon: any, title: React.ReactNode, description: React.ReactNode}) => (
                                <div key={feature.icon}>
                                    <Paper className='ViewOurAppPaperThree'>
                                        <OCVIcon style={{display:"inline", padding: "5px"}} classes={classes} width={"50"} height={"50"} color={"#1e824c"} type={feature.icon} />
                                        <h5 className='ViewOurAppH5'>{feature.title}</h5>
                                        <p>{feature.description}</p>
                                    </Paper>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Paper>
            </Col>
        </Row>
    );
};