import React from 'react';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css';
import {Row, Col} from 'reactstrap';
import withStyles from "@material-ui/core/styles/withStyles";
import sliderStyle from "../../../styles/jss/sliderStyle";
import ReactLoading from "react-loading";
import './Slider.css';

interface Props { // How to add to functional equivalent?
    classes? : any;
    sliderConfig: any;
};

export default withStyles(sliderStyle)(function Slider(props: Props) {
    const {classes, sliderConfig} = props;
    const params = {
        parallax: true,
        slidesPerView: 1,
        speed: 4000,
        loop: true,
        containerClass: classes.innerContainer,
        autoplay: {
            delay: sliderConfig.interval * 1000,
            disableOnInteraction: false
        },
        effect: 'fade'
    };
    return(
        <div>
            {(sliderConfig.items.length !== 0) ? <Row className={classes.slider} justify={'center'}>
                <Col style={{padding: 0}} xs={12}>
                    <Swiper {...params}>
                        {sliderConfig && sliderConfig.items.map((item: {url: string | undefined, index: string | undefined, altText: string | undefined}) => (
                            <img className={classes.images} src={item.url} key={item.altText} alt={item.altText} />
                        ))}
                    </Swiper>
                </Col>
            </Row> : <div className='SliderDiv'><ReactLoading className='loading-centered' type={"bars"} color={'#FFF'} height={'10%'} width={'10%'} /></div>}
        </div>
    );
});