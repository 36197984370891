import React, {useState} from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {Row, Col} from 'reactstrap';
import {Link, NavLink} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconName} from "@fortawesome/fontawesome-svg-core";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ReactModal from "react-modal";
import OCVVideoPlayer from "../../OCVFeatures/OCVVideoPlayer/OCVVideoPlayer";
import FacebookWidget from "../FacebookWidget";
import TwitterWidget from "../TwitterWidget";
import OCVBlogSlider from "../../OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider";
import HorizontalSublayout from "../HorizontalSublayout/HorizontalSublayout";
import './TopBar.css';

interface Props { // How to add to functional equivalent?
    classes: any;
    topbarConfig: any;
    manifestData: any;
};

ReactModal.setAppElement('#root');

export default function TopBar(props: Props) {
    const [showModal, setModal] = useState(false);
    const [modalView, setModalView] = useState(Object.keys(props.manifestData['views'])[0]);
    const [modalTitle, setModalTitle] = useState("");

    const handleOpenModal = (view: string, title: string) => {
        setModal(true);
        setModalView(view);
        setModalTitle(title);
    };
    const handleCloseModal = () => {
        setModal(false);
    };

    const getView = (view: string, manifestData: any, classes: any) => {
        const config = manifestData['views'][view]['config'];
        const manifestImage = manifestData['stylesheet'].images[config['image']];
        const manifestFeatures = manifestData['features'][config['feature']];
        const manifestDataStyleSheet = manifestData["stylesheet"];
        const paddingTop = config["paddingTop"];
        const paddingBottom = config["paddingBottom"];
        const primaryColors = manifestDataStyleSheet["colors"]["primary"];
        const backgroundColor = config['backgroundColor'];

        switch (props.manifestData['views'][view]['type']) {
            case 'imageView':
                return <img className='TopBarImageView' src={manifestData['stylesheet'].images ? manifestImage['url'] : ''} alt={manifestData['stylesheet'].images ? manifestImage['altText'] : ''}/>;
            case 'button':
                return <div style={{
                    paddingTop: (paddingTop) ? paddingTop : 0, paddingBottom: (paddingBottom) ? paddingBottom : 0,
                    paddingLeft: (config["paddingLeft"]) ? config["paddingLeft"] : 0, paddingRight: (config["paddingRight"]) ? config["paddingRight"] : 0,}}>
                    {(manifestFeatures['type'] === "modal") ? <button style={{
                            background: (backgroundColor) ? backgroundColor : primaryColors,
                        }} type={"button"} onClick={() => handleOpenModal(manifestFeatures['view'],manifestFeatures['title'])} className={classes.homepageButton}>{config['title']}</button>
                        : (manifestFeatures['type'] !== "webview" || !manifestFeatures['subtype'].includes('external')) ? <Link to={config['feature']}>
                            <button style={{
                                background: (backgroundColor) ? backgroundColor : primaryColors,
                            }} type={"button"} className={classes.homepageButton}>{config['title']}</button>
                        </Link> : <a target="_blank" rel="noopener noreferrer" href={manifestFeatures['url']}>
                            <button style={{
                                background: (backgroundColor) ? backgroundColor : primaryColors,
                            }} type={"button"} className={classes.homepageButton}>{config['title']}</button>
                        </a>
                    }
                </div>;
            case 'label':
                return <p dangerouslySetInnerHTML={{__html: config['text']}} />;
            case "horizontalLayout":
                return <HorizontalSublayout classes={classes} manifestData={manifestData} config={config} />;
            case "video":
                return <OCVVideoPlayer config={config} />;
            case "facebookWidget":
                return <FacebookWidget config={config} />;
            case "twitterWidget":
                return <TwitterWidget config={config} />;
            case "blogSlider":
                return <OCVBlogSlider link={manifestFeatures['url']} />;
        };
    };

    const {classes, topbarConfig, manifestData} = props;
    return(
        <Row className={classes.topBar}>
            {(topbarConfig["alignment"] === "right") ? <Col className={classes.topSocial} xs={10}>
                <List className={classes.topSocialList}>
                    <ListItem className={classes.topLinksItem}>
                        <NavLink className={classes.topLink} to={'/viewOurApp'}>VIEW OUR APP</NavLink>
                    </ListItem>
                    {topbarConfig['links'] ? topbarConfig['links'].map((item: any) => (
                        (item.link) ? <ListItem key={item.title} className={classes.topLinksItem}>
                            <a rel="noopener noreferrer" target={"_blank"} className={(item.isButton) ? classes.smallButton : classes.topLink} href={item.link}>{item.title}</a>
                        </ListItem> : <ListItem key={item.title} className={classes.topLinksItem}>
                                {
                                    (manifestData['features'][item.feature]['type'] === 'modal') ? <span className={(item.isButton) ? classes.smallButton : classes.topLink} onClick={() => handleOpenModal(manifestData['features'][item.feature]['view'], manifestData['features'][item.feature]['title'])}>{item.title}</span> : <NavLink to={'/' + item.feature} className={(item.isButton) ? classes.smallButton : classes.topLink}>{item.title}</NavLink>
                                }
                        </ListItem>)) : ''
                    }
                    {topbarConfig['socialMedia'].map((item: {type: IconName, link: string | undefined}) => (
                        <ListItem key={item.type} className={classes.topSocialListItem}>
                            <a rel="noopener noreferrer" target={"_blank"} aria-label={item.type} className={classes.topSocialLink} href={item.link}>
                                <FontAwesomeIcon size={'lg'} icon={['fab', item.type]} />
                            </a>
                        </ListItem>
                    ))}
                </List>
            </Col> : ""}
            {(topbarConfig["alignment"] ===  "left") ? <Col className={classes.topSocial} xs={10}>
                <List className={classes.topSocialList}>
                    <ListItem className={classes.topLinksButton}>
                        <NavLink className={classes.smallButton} to={'/viewOurApp'}>VIEW OUR APP</NavLink>
                    </ListItem>
                    {topbarConfig['links'] ? topbarConfig['links'].map((item: any) => (
                        (item.link) ? <ListItem key={item.title} className={classes.topLinksItem}>
                                <a rel="noopener noreferrer" target={"_blank"} className={classes.topLink} href={item.link}>{item.title}</a>
                            </ListItem>
                            : <ListItem key={item.title} className={classes.topLinksItem}>
                                <NavLink to={'/' + item.feature} className={classes.topLink}>{item.title}</NavLink>
                            </ListItem>)) : ''
                    }
                    {topbarConfig['socialMedia'].map((item: {type: IconName, link: string | undefined}) => (
                        <ListItem key={item.type} className={classes.topSocialListItem}>
                            <a rel="noopener noreferrer" target={"_blank"} aria-label={item.type} className={classes.topSocialLink} href={item.link}>
                                <FontAwesomeIcon size={'lg'} icon={['fab', item.type]} />
                            </a>
                        </ListItem>
                    ))}
                </List>
            </Col> : ""}
            {(topbarConfig["alignment"] === "both") ? <>
                <Col className={classes.topLinks}  xs={10} lg={5}>
                    <List className={classes.topLinksList}>
                        <ListItem className={classes.topLinksButton}>
                            <NavLink className={classes.smallButton} to={'/viewOurApp'}>VIEW OUR APP</NavLink>
                        </ListItem>
                        {topbarConfig['links'] ? topbarConfig['links'].map((item: any) => (
                            (item.link) ? <ListItem key={item.title} className={classes.topLinksItem}>
                                    <a rel="noopener noreferrer" target={"_blank"} className={classes.topLink} href={item.link}>{item.title}</a>
                                </ListItem>
                                : <ListItem key={item.title} className={classes.topLinksItem}>
                                    <NavLink to={'/' + item.feature} className={classes.topLink}>{item.title}</NavLink>
                                </ListItem>)) : ''
                        }
                        <ListItem className={classes.topLinksItem}>
                            <NavLink className={classes.topLink} to={'/search'}>Search</NavLink>
                        </ListItem>
                    </List>
                </Col>
                <Col className={classes.topSocial} xs={10} lg={5}>
                    <List className={classes.topSocialList}>
                        {topbarConfig['socialMedia'].map((item: {type: IconName, link: string | undefined}) => (
                            <ListItem key={item.type} className={classes.topSocialListItem}>
                                <a rel="noreferrer noopener" target={"_blank"} aria-label={item.type} className={classes.topSocialLink} href={item.link}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', item.type]} />
                                </a>
                            </ListItem>
                        ))}
                    </List>
                </Col>
            </>:""}
            <ReactModal className='TopBarModalContent' overlayClassName='TopBarModal' contentLabel="Minimal Modal Example" onRequestClose={() => handleCloseModal()} isOpen={showModal}>
                <div className='TopBarDiv'>
                    <h4 className="TopBarModalTitle">{modalTitle}</h4>
                    <IconButton aria-label="Close" className='TopBarCloseButton' onClick={() => handleCloseModal()}>
                        <FontAwesomeIcon className="TopBarIcon" icon={["fas", "times"]}/>
                    </IconButton>
                </div>
                {getView(modalView, manifestData, classes)}
            </ReactModal>
        </Row>
    );
};