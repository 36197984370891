import React, {Component} from 'react';
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import {Col, Row} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import Pagination from "../../../MainComponents/Pagination";
import Paper from "@material-ui/core/Paper/Paper";
import Lightbox from "react-images";
import ContentLoader from "react-content-loader";
import './OCVBlogContainer.css';

export default withRouter(class OCVBlogContainer extends Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            blogData: [],
            link: this.props.link,
            perPage: this.props.perPage,
            currentPage: 1,
            totalPages: 0,
            currentEntries: [],
            blogLength: 0,
            lightboxIsOpen: false,
            currentImage: ""
        };
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
    };

    componentDidUpdate(prevProps: {blogData: any}) {
        if(this.props.blogData !== prevProps.blogData) {
            this.setState({
                blogData: this.props.blogData,
                totalPages: this.props.blogData.length/this.state.perPage,
                blogLength: this.props.blogData.length
            });
        }
    };

    onPageChanged = (data: { currentPage: number; totalPages: number; pageLimit: number; }) => {
        const {currentPage, totalPages, pageLimit} = data;
        const {blogData} = this.props;
        const offset = (currentPage - 1) * pageLimit;
        const currentEntries = blogData.slice(offset, offset + pageLimit);
        this.setState({currentPage, currentEntries, totalPages});
    };
    openLightbox = (event: {preventDefault: () => void}, image: string) => {
        event.preventDefault();
        this.setState({
            lightboxIsOpen: true,
            currentImage: image
        });
    };
    closeLightbox = () => {
        this.setState({
            lightboxIsOpen: false
        });
    };
    MyLoader = () => (
        <ContentLoader>
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
    );

    render() {
        const {classes, route} = this.props;
        return(
            <>
                <Row className='OCVBlogContainerRow'>
                    {(this.state.currentEntries.length !== 0) ? this.state.currentEntries
                        .map((blogEntry: any, index: number) => (
                            <Col key={blogEntry.title+index} className='OCVBlogContainerCol' style={{padding: '20px'}} xs={10} sm={10} md={10}>
                            <LazyLoad once>
                                <Paper className='OCVBlogContainerPaperOne'>
                                    <div className='OCVBlogContainerExternalDiv'>
                                        {(blogEntry.images.length === 0) ? null :
                                            <div className='OCVBlogContainerInternalDivOne'>
                                                <div onClick={(e) => this.openLightbox(e, blogEntry.images[0].large)} className='OCVBlogContainerNestedDivOne' style={{paddingTop: '64.18%'}}>
                                                    <img alt={blogEntry.title} className='OCVBlogContainerNestedImg' src={blogEntry['images'].length !== 0 ?  blogEntry.images[0].large : null} />
                                                </div>
                                            </div>}
                                        <div className='OCVBlogContainerInternalDivTwo'>
                                            <h4>{blogEntry.title}</h4>
                                            {!("date" in blogEntry) ? '' : <span className='OCVBlogContainerSpan'><strong>Posted on {moment.unix((blogEntry.date !== null) ? blogEntry['date']['sec'] : ' ').format('LL')}</strong></span>}
                                            <div className={classes.blogEllipsis + ' OCVBlogContainerNestedDivTwo'} dangerouslySetInnerHTML={{__html: blogEntry.content}} />
                                            <Link className='OCVBlogContainerLinkOne' to={{
                                                pathname: `/${route}/${index + (this.state.currentPage - 1) * this.state.perPage}`,
                                            }}>Read More</Link>
                                            {(this.props.submitATipLink === true)
                                                ? <Link className='OCVBlogContainerLinkTwo' to={"/submitATip"}>
                                                        <button type={"button"} className={"btn btn-danger"}>Submit a Tip</button>
                                                </Link> : ""}
                                            </div>
                                        </div>
                                    </Paper>
                                </LazyLoad>
                            </Col>
                        ))
                        : <Col className='OCVBlogContainerCol' xs={10} sm={10} md={10}>
                            <Paper className='OCVBlogContainerPaperTwo'>
                                <this.MyLoader />
                            </Paper>
                        </Col>}
                </Row>
                <Lightbox backdropClosesModal onClose={this.closeLightbox} isOpen={this.state.lightboxIsOpen} images={[{src:this.state.currentImage}]}/>
                <Row>
                    <Col xs={10} sm={10} md={8}>
                        <Pagination
                            totalRecords={this.state.blogLength}
                            pageLimit={this.state.perPage}
                            pageNeighbours={1}
                            onPageChanged={this.onPageChanged}
                        />
                    </Col>
                </Row>
            </>
        )
    }
});